import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useToast } from 'vue-toastification';
import MainLayout from '@/components/common/MainLayout.vue';
import ActiveExamLayout from '@/components/common/ActiveExamLayout.vue';
import AuthLayout from '@/components/common/AuthLayout.vue';
import i18n from '@/i18n';
import authService from '@/services/authService';

// Define the route records
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: AuthLayout,
    children: [
      { path: 'login', name: 'Login', component: () => import('@/views/auth/LoginView.vue'), meta: { requiresAuth: false, title: 'page_titles.login' } },
    ]
  },
  { path: '/restricted', name: 'Restricted', component: () => import('@/views/RestrictedView.vue'), meta: { title: 'page_titles.restricted' } },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('@/views/NotFoundView.vue'), meta: { title: 'page_titles.not_found' } },
  {
    path: '',
    component: MainLayout,
    meta: { requiresAuth: true },
    children: [
      { path: '', name: 'Home', component: () => import('@/views/students/Exams.vue'), meta: { title: 'page_titles.my_exams' } },
    ]
  },

  {
    path: '',
    component: ActiveExamLayout,
    meta: { requiresAuth: true },
    children: [
      { path: '/exam/:id', name: 'ActiveExam', component: () => import('@/views/students/ActiveExam.vue'), meta: { title: 'page_titles.my_exam' } },
    ]
  }
];

// Create the router instance and pass the `routes` option
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

/**
 * Navigation guard to check if the user is authenticated before each route change.
 * @param to - the target Route object being navigated to
 * @param from - the current route being navigated away from
 * @param next - a function that must be called to resolve the hook
 */
router.beforeEach(async (to, from, next) => {
  const { t } = i18n.global;
  const toast = useToast();

  const pageTitle = to.meta.title ? t(to.meta.title as string) : t('site.title');
  document.title = `${pageTitle} | ${t('site.title')}`;

  if (to.meta.requiresAuth !== false) {
    try {

      const userProfile = await authService.getProfile();
      if (!userProfile?.user) {
        await authService.logout();
        return;
      }

      const basePermission = 'com.ltm.permissions.students.can.take.exam'; // Base permission to access the system.
      const requiredPermission = to.meta.permission;
      const hasBasePermission = userProfile?.user.permissions.some((p: any) => p.permission === basePermission) && userProfile?.student?.status;

      if (!hasBasePermission) { // If the user does not have the base permission, logout and redirect to login page.
        await authService.logout();
        toast.error(t('user.login.permission_denied'));
        return;
      }

      if (requiredPermission && !userProfile?.user.permissions.some((p: any) => p.permission === requiredPermission)) {
        next({ name: 'Restricted' });
        return;
      }

      next();
    } catch (error) {
      next({ name: 'Login' });
    }
  } else {
    next();
  }
});


export default router;