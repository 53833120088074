import apiClient from './apiService';
import store from '@/store';
import { LoginCredentials, User, Profile } from '@/interfaces/User';
import axios from 'axios';
import studentsService from './studentsService';

class AuthService {
    // TODO REMOVE
    async login(credentials: LoginCredentials): Promise<void> {
        try {
            const response = await apiClient.post<ApiResponse<User>>('/login', credentials);
            const user: User = response.data.data;
            store.dispatch('currentUser/updateUser', user);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 401) {
                    throw new Error('Invalid login details');
                }
            }
            throw new Error('An unexpected error occurred during login');
        }
    }

    // Gets the user and student profile from the server.
    async getProfile(): Promise<Profile | null> {
        try {
            const response = await apiClient.get<ApiResponse<User>>('/user');
            const user = response.data.data;

            const studentInfoResponse = await studentsService.getStudentProfile(user.id);
            const student = studentInfoResponse.data;

            store.dispatch('currentUser/updateUser', user);
            store.dispatch('currentUser/updateStudentProfile', student);
            return { user, student };
        } catch (error) {
            store.dispatch('currentUser/updateUser', null);
            store.dispatch('currentUser/updateStudentProfile', null);
            return null;
        }
    }

    async logout(): Promise<void> {
        const response = await apiClient.post('/logout');
        if (response.status === 200) {
            if (response.data.returnUrl) {
                // Redirect to the returnUrl
                window.location.href = response.data.returnUrl;
            } else {
                // Redirect to the login page
                window.location.href = '/login';
            }

            // Margin to clear the session and state after the redirect has started
            setTimeout(() => {
                store.dispatch('currentUser/updateUser', null);
                store.dispatch('currentUser/updateStudentProfile', null);
                sessionStorage.clear();
            }, 500); // 500ms should be sufficient
        }
    }

    isAuthenticated(): boolean {
        return !!store.getters['currentUser/isAuthenticated'];
    }
}

export default new AuthService();