<template>
  <div>
    <button @click.prevent="showConfirmationDialog = true" :class="buttonClass">
      <slot></slot>
    </button>
    <ConfirmationDialog :show="showConfirmationDialog" :title="title ? title : $t('common.confirm_action_title')" :message="message ? message : $t('common.confirm_action_message')" @confirm="handleConfirm" @cancel="handleCancel" />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

// Props
const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  message: {
    type: String,
    default: null,
  },
  onConfirm: {
    type: Function,
    required: true,
  },
  buttonClass: {
    type: String,
    default: "min-w-5 min-h-5 flex rounded-md", // default class for the button if needed
  },
});

// Reactive state
const showConfirmationDialog = ref(false);

// Methods
const handleConfirm = () => {
  props.onConfirm();
  showConfirmationDialog.value = false;
};

const handleCancel = () => {
  showConfirmationDialog.value = false;
};
</script>

<style scoped></style>
