<template>
  <div v-if="!isMobileOrPortraitTablet" class="h-screen flex flex-col">
    <Header />
    <main class="flex-1 sm:px-6 py-6 md:px-8 lg:px-12 xl:px-16 overflow-auto">
      <router-view></router-view>
    </main>
  </div>
  <BlockPage v-else />
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from "vue";
import Header from "./Header.vue";
import BlockPage from "./BlockPage.vue";

const isMobileOrPortraitTablet = ref(false);

const detectDevice = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;

  // Detect landscape mode
  const isLandscape = width > height;

  // Defining breakpoints for tablet in landscape mode and desktops
  if (width >= 768 && isLandscape) {
    isMobileOrPortraitTablet.value = false;
  } else {
    isMobileOrPortraitTablet.value = true;
  }
};

onMounted(() => {
  detectDevice();
  window.addEventListener("resize", detectDevice);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", detectDevice);
});
</script>

<style scoped></style>
